
































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { Unit } from '../classes/unit';
import moment from 'moment';
import { MetricsService } from '../services/metricsService';



const databasePostfix = process.env.VUE_APP_DATABASE_POSTFIX || '.epi.intra'
const domain = process.env.VUE_APP_DATABASE_POSTFIX ? 'd90' : 'd60'


@Component({
  components: {
    PerfectScrollbar
  },
  computed: {
    ...mapGetters({
      sidebarOpen: 'avgSidebarIsOpen',
      activeRightSidebar: 'getActiveRightSidebar',
      configuration: 'getConfiguration',
      units: 'getUnits',
      path: 'getPath',
      timeRange: 'getTimeRange'
    }),
  },
  asyncComputed: {
    async links() {
      const defaultLinks = [
        {
          title: 'User Guide Continuous',
          href: `https://${domain}c.sharepoint.com/sites/SC00120T01415/EPI%20Documentation/Forms/Manuals.aspx?id=%2Fsites%2FSC00120T01415%2FEPI%20Documentation%2FGMT%2DHT%2D86%20%2D%20OAM%20Portal%20UX%20User%20Guide%2Epdf&parent=%2Fsites%2FSC00120T01415%2FEPI%20Documentation`
        },
        {
          title: 'User Guide Batch',
          href: `https://${domain}c.sharepoint.com/sites/SC00120T01415/EPI%20Documentation/Forms/Manuals.aspx?id=%2Fsites%2FSC00120T01415%2FEPI%20Documentation%2FGMT%2DHT%2D87%20OAM%20Portal%20UX%20Batch%20User%20Guide%2Epdf&parent=%2Fsites%2FSC00120T01415%2FEPI%20Documentation`
        },
        {
          title: 'OAM Dashboard',
          //@ts-ignore
          href: this.OAMLink
        },
        {
          title: 'AUM Dashboard',
          //@ts-ignore
          href: this.AUMLink
        },
        {
          title: 'PEM Dashboard',
          //@ts-ignore
          href: this.PEMLink
        },
        {
          title: 'OAM Planner',
          href: `${window.location.origin}/oam-planner${window.location.search}`
        }
      ];

      const links = await import(
      '../assets/' + process.env.VUE_APP_ASSET_PATH + '/report-links.json'
        );

      return links && links.default.length ? links.default : defaultLinks
    }
  }
})


export default class AvgSidebar extends Vue {
  public units!: Unit[];
  public activeRightSidebar!: string;
  public sidebarOpen!: boolean;
  public timeRange!: Date[];
  public path!: any;
  public range: any = {};
  public configuration!: any;
  public defaultLinks = []

  get currency() {
    return this.configuration[this.path].Currency || 'EUR';
  }

  get OAMLink() {
    const windowsTimezoneIdentifier = this.configuration[this.path].windowsTimezoneIdentifier ?? 'GMT Standard Time';
    const serverName = this.configuration[this.path].Database.includes(
        databasePostfix
      )
        ? this.configuration[this.path].Database
        : this.configuration[this.path].Database + databasePostfix,
      AUMUnitIDList = this.units
        .filter((unit) => unit.Type === 'AUM')
        .map((unit) => unit.externalId)
        .join(','),
      PEMUnitIDList = this.units
        .filter((unit) => unit.Type === 'PEM')
        .map((unit) => unit.externalId)
        .join(',');

    let startTime = moment(this.timeRange[0]).format('YYYY-MM-DD HH:mm:ss'),
      endTime = moment(this.timeRange[1]).format('YYYY-MM-DD HH:mm:ss');

    if (moment(this.timeRange[0]).diff(moment(this.timeRange[1]), 'd') < 7) {
      startTime = moment()
        .startOf('d')
        .subtract(7, 'd')
        .format('YYYY-MM-DD HH:mm:ss');
      endTime = moment().startOf('d').format('YYYY-MM-DD HH:mm:ss');
    }

    return `https://report1${databasePostfix}/ReportServer/Pages/ReportViewer.aspx?/GMT/OAM/OAM%20Dashboard&ServerName=${serverName}&TimeZone=${windowsTimezoneIdentifier}&AUMUnitIDList=${AUMUnitIDList}&PEMUnitIDList=${PEMUnitIDList}&AUMFactorReasonLevel=1&CostCurrency=${this.currency}&StartTime_UI=${startTime}&EndTime_UI=${endTime}`;
  }

  get PEMLink() {
    const windowsTimezoneIdentifier = this.configuration[this.path].windowsTimezoneIdentifier ?? 'GMT Standard Time';
    const serverName = this.configuration[this.path].Database.includes(
        databasePostfix
      )
        ? this.configuration[this.path].Database
        : this.configuration[this.path].Database + databasePostfix,
      PEMUnitIDList = this.units
        .filter((unit) => unit.Type === 'PEM')
        .map((unit) => unit.externalId)
        .join(',');

    let startTime = moment(this.timeRange[0]).format('YYYY-MM-DD HH:mm:ss'),
      endTime = moment(this.timeRange[1]).format('YYYY-MM-DD HH:mm:ss');

    if (moment(this.timeRange[0]).diff(moment(this.timeRange[1]), 'd') < 7) {
      startTime = moment()
        .startOf('d')
        .subtract(7, 'd')
        .format('YYYY-MM-DD HH:mm:ss');
      endTime = moment().startOf('d').format('YYYY-MM-DD HH:mm:ss');
    }

    return `https://report1${databasePostfix}/ReportServer/Pages/ReportViewer.aspx?/GMT/OAM/PEM/PEM%20Dashboard&ServerName=${serverName}&TimeZone=${windowsTimezoneIdentifier}&StartTime_UI=${startTime}&EndTime_UI=${endTime}&UnitIDList=${PEMUnitIDList}&CostCurrency=${this.currency}&ReverseCommentOrder=%200`;
  }

  get AUMLink() {
    const windowsTimezoneIdentifier = this.configuration[this.path].windowsTimezoneIdentifier ?? 'GMT Standard Time';
    const serverName = this.configuration[this.path].Database.includes(
        databasePostfix
      )
        ? this.configuration[this.path].Database
        : this.configuration[this.path].Database + databasePostfix,
      AUMUnitIDList = this.units
        .filter((unit) => unit.Type === 'AUM')
        .map((unit) => unit.externalId)
        .join(',');

    let startTime = moment(this.timeRange[0]).format('YYYY-MM-DD HH:mm:ss'),
      endTime = moment(this.timeRange[1]).format('YYYY-MM-DD HH:mm:ss');

    if (moment(this.timeRange[0]).diff(moment(this.timeRange[1]), 'd') < 7) {
      startTime = moment()
        .startOf('d')
        .subtract(7, 'd')
        .format('YYYY-MM-DD HH:mm:ss');
      endTime = moment().startOf('d').format('YYYY-MM-DD HH:mm:ss');
    }

    return `https://report1${databasePostfix}/ReportServer/Pages/ReportViewer.aspx?/GMT/OAM/AUM/AUM%20Dashboard&ServerName=${serverName}&TimeZone=${windowsTimezoneIdentifier}&StartTime_UI=${startTime}&EndTime_UI=${endTime}&UnitIDList=${AUMUnitIDList}&AUMFactorReasonLevel=1&CostCurrency=${this.currency}`;
  }

  linkClicked(link) {
    MetricsService.newAction({
      type: 'click',
      kind: 'report_link_clicked',
      typeFormated: 'Report link clicked',
      message: `The ${link.title} link was clicked and the url was ${link.href}`,
      time: moment.utc().format('YYYY-MM-DD HH:mm:ss')
    });
  }
}
